import React, { useMemo } from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Clock from '../images/ico-svg/history-light.svg'
import Marker from '../images/ico-svg/map-marker-alt-light.svg'
import File from '../images/ico-svg/file-light.svg'
import Zip from '../images/ico-svg/file-zip-light.svg'
import Pdf from '../images/ico-svg/file-pdf-light.svg'
import Download from '../images/ico-svg/arrow-alt-to-bottom-light.svg'
import ArticoloBox from '../components/articolo-box'
import TextDecorator from '../components/text-decorator'
import Accordion from '../components/accordion'
import { slugPage, replaceHtml } from '../utility/utils'


const TemplatePaginaInterna = ({ pageContext: { breadcrumbs, myPath }, data }) => {
  const { pagina, storico } = data;
  const { immagineHero, abilitaHeroSafeArea, titolo, titoloInPagina, testo, pagineFiglie, figlieVisibili, articoliBox,
    trovaAgenzia, storicoProdotti, documenti, modulistica, link, disclaimer } = pagina;

  const boxSpallaDestra = articoliBox || trovaAgenzia || storicoProdotti || documenti || modulistica || link;

  const testoMd = testo && testo.childMarkdownRemark.html;
  const testoHtml = useMemo(() => testoMd ? replaceHtml(testoMd) : null, [testoMd]);
  const linkFiglia = page => {
    const ghostPage = (page.pagineFiglie && page.pagineFiglie.find(item => !!item.prodotto_archiviato)) || page.pagineQuotazioni;
    if (ghostPage) {
      if (page.pagineFiglie) {
        return `${myPath}/${slugPage(page)}-${slugPage(page.pagineFiglie[0])}/`;
      } else {
        return `${myPath}/${slugPage(page)}-${slugPage(page.pagineQuotazioni[0])}/`;
      }
    }
    return `${myPath}/${slugPage(page)}/`;
  }

  return (
    <Layout data={data}>
      <SEO page={pagina} data={data} />

      <div id="main" role="main">
        {/* Hero */}
        {immagineHero && (
          abilitaHeroSafeArea ? (
            <div className="hero hero--sa" style={{ backgroundImage: `url(${immagineHero.fixed.src})` }}></div>
          ) : (
            <div className="hero">
              <Img fluid={immagineHero.fluid} alt={titolo} objectFit="cover" objectPosition="center center" />
            </div>
          )
        )}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`${immagineHero ? 'prodotto template-interno has-hero' : 'prodotto template-interno'}`}>
                <div className="row">
                  <div className={`${(boxSpallaDestra) ? 'col-md-8' : 'col-12'}`}>
                    {/* Breadcrumb */}
                    <div id="breadcrumbs" className="breadcrumbs clearing" role="navigation" aria-label="breadcrumb">
                      <ol className="breadcrumbs__list" itemType="http://schema.org/BreadcrumbList" itemScope="itemscope">
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level="1" itemProp="item">
                            <Link to="/" title="Homepage" className="breadcrumbs__link" itemProp="url"><span itemProp="name">Homepage</span></Link>
                          </div>
                          <meta content="1" itemProp="position" />
                        </li>
                        {breadcrumbs.map((item, i) => (
                          <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement" key={i}>
                            <div aria-level={i + 2} itemProp="item">
                              <Link to={item.link} title={item.name} className="breadcrumbs__link" itemProp="url"><span itemProp="name">{item.name}</span></Link>
                            </div>
                            <meta content={i + 2} itemProp="position" />
                          </li>
                        ))}
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level={breadcrumbs.length + 2} itemProp="item">
                            <span itemProp="name">{titolo}</span>
                          </div>
                          <meta content={breadcrumbs.length + 2} itemProp="position" />
                        </li>
                      </ol>
                    </div>

                    {/* Titolo  */}
                    {titoloInPagina ? (
                      <h1 className="prodotto__title prodotto__title--titoloinpagina"><TextDecorator>{titoloInPagina}</TextDecorator></h1>
                    ) : (
                        <h1 className="prodotto__title">{titolo}</h1>
                      )}

                    {/* Testo */}
                    {testo && <div className="prodotto__text" dangerouslySetInnerHTML={{ __html: testoHtml }}></div>}

                    {/* Lista pagine figlie */}
                    {pagineFiglie && figlieVisibili && (
                      pagineFiglie.filter(item => !item.nascondiDallaLista).map((item, i) => (
                        <div className="box-prodotto" key={i}>
                          <h2 className="box-prodotto__title">
                            <Link to={linkFiglia(item)}
                              title={item.titolo} className="box-prodotto__link">
                              {item.titolo}
                            </Link>
                          </h2>
                          {item.descrizione && (
                            <div className="box-prodotto__desc" dangerouslySetInnerHTML={{ __html: item.descrizione.childMarkdownRemark.html }}></div>
                          )}
                        </div>
                      ))
                    )}

                    {/* Disclaimer */}
                    {disclaimer && disclaimer.map((item, i) => (
                      <div className={`prodotto__disclaimer${item.mostraSeparatore ? ' prodotto__disclaimer--border' : ''}`} dangerouslySetInnerHTML={{ __html: item.testo.childMarkdownRemark.html }} key={i}></div>
                    ))}
                  </div>

                  {boxSpallaDestra && (
                    <div className="col-md-4">
                      {storicoProdotti && (
                        <Link to={`/prodotti/${slugPage(storico)}/`} title={storico.titolo} className="btn-link-icon mb-4"><span className="ico-svg"><Clock /></span>{storico.titolo}</Link>
                      )}

                      {articoliBox && articoliBox.map((box, i) => (
                        <div key={i}>
                          <ArticoloBox box={box} />
                        </div>
                      ))}

                      {documenti && (
                        <>
                          <span className="btn-link-icon">Documentazione<span className="ico-svg"><File /></span></span>
                          <div className="wrapper-accordion">
                            {documenti.map((doc, i) => (
                              <Accordion title={doc.titolo} startOpen={i === 0}>
                                <div>
                                  <div className="box-link">
                                    {doc.documenti.map((file, j) => (
                                      <div key={j}>
                                        <a href={file.relativeUrl} title={file.description} className="box-link__doc" target="_blank" rel="noopener noreferrer">
                                          <span className="ico-svg">
                                            <Pdf />
                                          </span>
                                          {file.description}
                                        </a>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </Accordion>
                            ))}
                          </div>
                        </>
                      )}

                      {modulistica && (
                        <>
                          <span className="btn-link-icon"><span className="ico-svg"><Pdf /></span>Modulistica</span>
                          <div className="box-link">
                            {modulistica.map((item, i) => (
                              <div key={i}>
                                <a href={item.relativeUrl} title={item.description} className="box-link__doc" target="_blank" rel="noopener noreferrer">
                                  <span className="ico-svg">
                                    {(!!item.file.contentType && item.file.contentType === 'application/zip')?(
                                      <Zip />
                                    ):(
                                      <Pdf />
                                    )}
                                  </span>
                                  {item.description}
                                </a>
                              </div>
                            ))}
                          </div>
                        </>
                      )}

                      {trovaAgenzia && (
                        <a href="http://www.bancobpmspa.com/index.php" title="Trova agenzia" className="btn-link-icon mb-4" target="_blank" rel="noopener noreferrer">
                          <span className="ico-svg"><Marker /></span>Trova agenzia
                        </a>
                      )}

                      {link && (
                        <>
                          <span className="btn-link-icon">Link<span className="ico-svg"><Download /></span></span>
                          <div className="box-link">
                            {link.map((item, i) => (
                              <div key={i}>
                                <a href={item.url} title={item.titoloLink}>{item.testoLink}</a>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TemplatePaginaInterna;

export const query = graphql`
query PaginaInternaQuery($contentful_id: String!){
  ...DefaultSeo
  ...HeaderFragment
  ...FooterRootFragment
  pagina: contentfulLayoutPaginaInterna(contentful_id: {eq: $contentful_id}) {
    seo {
      title
      friendlyUrl
      description
      robots
      ogtitle
      ogdescription
      ogimage{
        fixed(width: 1920, quality: 100){
          src
        }
      }
    }
    titolo
    titoloInPagina
    articoliBox {
      ...ArticoloBox
    }
    immagineHero {
      fluid(maxWidth:1920) {
        ...GatsbyContentfulFluid_withWebp
      }
      fixed(height: 452) {
        ...GatsbyContentfulFixed_withWebp
      }
    }
    abilitaHeroSafeArea
    pagineFiglie {
      titolo
      seo {
        friendlyUrl
      }
      nascondiDallaLista
      descrizione {
        childMarkdownRemark {
          html
        }
      }
      pagineFiglie{
        titolo
        seo {
          friendlyUrl
        }
        prodotto_archiviato{
          contentful_id
        }
      }
      pagineQuotazioni {
        titolo
        seo {
          friendlyUrl
        }
      }
    }
    figlieVisibili
    testo {
      childMarkdownRemark {
        html
      }
    }
    trovaAgenzia
    storicoProdotti
    documenti {
      titolo
      documenti {
        title
        description
        file {
          url
        }
        relativeUrl
      }
    }
    modulistica {
      title
      description
      file {
        url
        contentType
      }
      relativeUrl
    }
    link {
      testoLink
      titleLink
      url
    }
    disclaimer {
      testo {
        childMarkdownRemark {
          html
        }
      }
      mostraSeparatore
    }
  }

  storico: contentfulLayoutPaginaInterna(contentful_id: {eq: "5yrTXelY6CxH8wv9lpYcv2"}) {
    titolo
    seo {
      friendlyUrl
    }
  }
}`;