import React, { useState } from 'react'

const Accordion = ({ className = '', title, startOpen = false, children }) => {

  const [open, setOpen] = useState(startOpen);

  return (
    <div className={`accordion${open ? ' open' : ''}${className ? ` ${className}`: ''}`}>
      <div className="accordion__title" onClick={() => setOpen(value => !value)}>
        <div>{title}</div>
      </div>
      <div className="accordion__content">
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
