import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "../components/link"

const LinkHoc = ({ link, children, ...other }) => {
  if (link){
    return <Link to={link} {...other} target={link.startsWith('http') ? '_blank' : '_self'}>{children}</Link>
  }
  return <>{children}</>
}

const ArticoloBox = ({ box }) => {
  return (
    <div className="box-home d-flex flex-column">
      <LinkHoc link={box.linkCta && box.linkCta.url} className="d-block">
        <figure className="box-home__fig">
          <Img fluid={box.immagine.fluid} alt={box.titolo} objectFit="cover" objectPosition="50% 50%" className="box-home__img"/>
          {box.etichetta && <span className="box-home__category">{box.etichetta}</span>}
        </figure>
      </LinkHoc>
      <div className="box-home__text flex-grow-1">
        {box.titolo && <div className="box-home__title">{box.titolo}</div>}
        {box.testo && (
          <div className="box-home__desc" dangerouslySetInnerHTML={{__html:box.testo.childMarkdownRemark.html}}></div>
        )}
      </div>
      {box.linkCta && (
        <div className="box-home__link mt-auto">
          <Link to={box.linkCta.url} title={box.linkCta.titleLink} target={box.linkCta.url.startsWith('http') ? '_blank' : '_self'}>{box.linkCta.testoLink}</Link>
        </div>
      )}
    </div>
  );
}

export default ArticoloBox

export const query = graphql`
  fragment ArticoloBox on ContentfulArticoloBox {
    titolo
    testo {
      childMarkdownRemark {
        html
      }
    }
    immagine {
      fluid(maxWidth:325) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    etichetta
    linkCta {
      testoLink
      titleLink
      url
    }
  }
`