import React from "react"

const TextDecorator = ({ children }) => {
  const index = children.indexOf('[più]');
  if (index >= 0){
    const pre = children.substring(0, index);
    const post = children.substring(index+5, children.length);
    return <>{ pre }<span className="purple">più</span>{ post }</>;
  }
  return <div>{ children }</div>;
}

export default TextDecorator;